import {useState} from 'react';
import {TeacherTab} from '../../../types/teacher-tab';
import TeacherTabs from '../tabs/tabs';
import TeacherProfile from '../teacher-profile/teacher-profile';
import TeacherMaterials from '../materials/teacher-materials';
import Schedule from '../../schedule/schedule';
import * as React from 'react';


function Teacher(): JSX.Element{
    const [teacherTab, setTeacherTab] = useState<TeacherTab>(TeacherTab.Materials);
    return (
        <article className="cabinet__wrapper">
            <TeacherTabs
                currentTab={teacherTab}
                updateTab={(tabName: TeacherTab) => {
                    setTeacherTab(tabName);
                }}
            />
            {teacherTab === TeacherTab.Materials && <TeacherMaterials />}
            {teacherTab === TeacherTab.Schedule && <Schedule />}
        </article>
    );
}

export default Teacher;