import React from "react";

type Props = {
    name: string;
    tasksCount: number;
    score: number;
}

export const FacultyInfo: React.FC<Props> = ({ name, tasksCount, score }) => {
    return <article className="faculty-info">
        <h3 className="faculty-info__header">
            Факультет<br />{name}
        </h3>
        <hr className="faculty-info__hr" />
        <dl className="faculty-info__desc">
            <dt>
                Задач решено:
            </dt>
            <dd className="faculty-info__dd">
                {tasksCount}
            </dd>
            <dt>
                Рейтинг:
            </dt>
            <dd className="faculty-info__dd">
                {score}
            </dd>
        </dl>
    </article>
}