import * as React from 'react';
import {useAppSelector} from "../../hooks";
import {MainPageBlocks} from "../../const";

function TicketZone(): JSX.Element{
    const price: number = useAppSelector((state) => state.price);

    return(
        <>
            <section className="ticket_zone__wrapper" 
                     id={MainPageBlocks.TicketZone}>
                <button
                    className="ticket_zone__button button button-l button-big"
                    onClick={() => (document.getElementById('modal_register') as HTMLDialogElement).showModal()}
                >
                    записаться
                </button>
                <div className="ticket_zone__ticket"></div>
                <span className="ticket_zone__infos ticket_zone__infos-children">
                    <p className="ticket_zone__count">16 детей</p>
                    <p className="ticket_zone__info">в одной группе</p>
                </span>
                
                <span className="ticket_zone__infos ticket_zone__infos-lessons">
                    <p className="ticket_zone__count">2 занятия</p>
                    <p className="ticket_zone__info">в неделю</p>
                </span>
                
                <span className="ticket_zone__infos ticket_zone__infos-price">
                    <p className="ticket_zone__count">{price} рублей</p>
                    <p className="ticket_zone__info">стоимость одного занятия</p>
                </span>
            </section>
            <hr className="separator"></hr>
        </>
        

    );
}

export default TicketZone;