import * as React from 'react';
import {Material} from '../../../types/main-data';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {useEffect} from 'react';
import {getMaterialAction, getMaterialsAction} from '../../../store/api-actions';
import EducationalMaterial from "../../educational-material/educational-material";
import {unwrapResult} from '@reduxjs/toolkit';

function StudentMaterials(): JSX.Element{
    const student = useAppSelector((state) => state.student);
    const classId = student!.classId;
    const materials = useAppSelector((state) => state.materials);
    const dispatch = useAppDispatch();
    useEffect(() => {
            if (classId !== 0) {
                dispatch(getMaterialsAction(classId))
            }},
        [classId, dispatch]
    );

    const getMaterialHref = (materialId: number): Promise<string> => {
        return new Promise((resolve, reject) => {
            dispatch(getMaterialAction(materialId)).then(unwrapResult).then((material) => {
                resolve(`data:application/pdf;base64,${material.content}`);
            }).catch(reject);
        });
    }
    
    return (
        <section className="materials__wrapper">
            <div className="">
                {materials.map((material: Material, index: number) =>
                    <div className="material" key={index}>
                        <a className="material__a"
                           href="#"
                           onClick={(event) => {
                               event.preventDefault();
                               getMaterialHref(material.id).then((href) => {
                                   const link = document.createElement('a');
                                   link.href = href;
                                   link.download = `${material.name}.pdf`;
                                   link.dispatchEvent(new MouseEvent('click'));
                               });
                           }}>
                            {material.name}
                        </a>
                    </div>)}
            </div>
        </section>
        
    );
}

export default StudentMaterials;