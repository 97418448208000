import * as React from 'react';
import {DescriptionCard} from "./description-card";

function Description(): JSX.Element{
    return(
        <section className="description__wrapper">
            <div className="description__cards">
                <DescriptionCard information={"Арифметика и алгебра, комбинаторика и теория графов, теория чисел, геометрия и математический анализ."}
                                 color={"description__card-first"} />
                <DescriptionCard information={"Сотни и тысячи необычных задач, фактов и теорем, которые возникают в необъятном мире математики."}
                                 color={"description__card-second"} />
                <DescriptionCard information={"Задачи с очень простой формулировкой и неожиданным способом их решения. Процесс поиска ответа в них доставляет большое удовольствие."}
                                 color={"description__card-third"} />
            </div>
            <p className="description__info">Всё это - олимпиадная математика в школе Hogmaths.</p>
        </section>

    );
}

export default Description;