import * as React from 'react';
import Schedule from "./schedule" ;

function ScheduleModal(): JSX.Element{

    const close = () =>{
        (document.getElementById('modal_schedule') as HTMLDialogElement).close();
    }


    return(
        <dialog className="modal_pc" 
                id="modal_schedule"
                onClick={(e) => e.target == e.currentTarget? close(): {}}>
            <h2 className="modal_pc__h">
                <button type="reset"
                        className="modal_pc__h-button"
                        onClick={close}>
                    <img src="/images/arrows/black_arrow.svg"
                         alt=""/>
                </button>
                Расписание занятий
            </h2>
            <Schedule/>
        </dialog>
    );
}

export default ScheduleModal;