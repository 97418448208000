import * as React from 'react';
import {MainPageBlocks} from "../../const";

function Advantages(): JSX.Element{
    return(
        <>
            <section className="advantages__wrapper" 
                     id={MainPageBlocks.Advantages}>
                <h1 className="advantages__h">Мы
                    <span className="advantages__h-bold"> не готовим к ЕГЭ </span>
                    и не учим школьной математике.
                </h1>
                <span className="advantages__infos">
                    <div className="advantages__info advantages__info-left">
                        <p className="advantages__info-text">Наша цель -
                            <span className="advantages__info-bold"> научить детей мыслить математически </span>
                        </p>
                    </div>
                    
                    <div className="advantages__info advantages__info-right">
                        <p className="advantages__info-text">Мы профессионально готовим школьников к 
                            <span className="advantages__info-bold"> математическим олимпиадам</span>
                        </p>
                    </div>
                    
                    
                </span>
            </section>
            <hr className="separator"></hr>
        </>     
    );
}

export default Advantages;