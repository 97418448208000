import * as React from 'react';
import {MainPageBlocks} from "../../const";

function TrainingProfiles(): JSX.Element{
    return (
        <section className="training_profiles__wrapper"
             id={MainPageBlocks.TrainingProfiles}>
            <h1 className="training_profiles__h">Направления подготовки</h1>
            <div className="training_profiles__profiles">
                <table className="training_profiles__profile training_profiles__profile-left">
                    <thead>
                        <tr>
                            <th className="training_profiles__profile__h">Классическая олимпиадная подготовка</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="training_profiles__profile__d">
                                <p className="training_profiles__profile__class">5 - 11 класс</p>
                                <p className="training_profiles__profile__info">Обучение классической олимпиадной математике</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="training_profiles__profile training_profiles__profile-right">
                    <thead>
                        <tr>
                            <th className="training_profiles__profile__h" >Подготовка к перечневым олимпиадам</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="training_profiles__profile__d">
                                <p className="training_profiles__profile__class">10 - 11 класс</p>
                                <p className="training_profiles__profile__info">Подготовка детей к олимпиадам из перечня олимпиад, учитывая их специфику</p>
                                <a className="training_profiles__profile__a" target="_blank" href="https://rsr-olymp.ru/">Список олимпиад</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default TrainingProfiles;