import * as React from 'react';
import {plusSlides, showSlides} from '../../utils/slider';
import {useState} from 'react';
import {useAppSelector} from '../../hooks';
import {TeacherPhoto} from '../../types/main-data';
import {TeachersSlider} from "./teachers_slider";
import {MainPageBlocks} from "../../const";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';

import "swiper/css";
import "swiper/css/navigation";
import {NavigationOptions} from 'swiper/types';

function Teachers(): JSX.Element{
    const teachers: TeacherPhoto[] = useAppSelector((store)=> store.teacherPhotos);
    const len = teachers.length
    const [index, setIndex] = useState(0);

    const nav: NavigationOptions = {
        nextEl: '.teachers_next',
        prevEl: '.teachers_prev',
    }
    

    return (
        <>
            {len > 0 && <section className="teacher__wrapper"
                                  id={MainPageBlocks.Teachers}>
                <h1 className="teacher__h">Наши преподаватели</h1>
                <article className="sliders_wrapper">
                    {len > 1 && <button className="sliders__arrow sliders__arrow-prev__yellow teachers_prev"
                                        onClick={() => {
                                            const checker = document.getElementById("read-more-checker_teacher" + index);
                                            if (checker !== null)
                                                (checker as HTMLInputElement).checked = false;
                                            setIndex((prev) => ((len + prev - 1) % len))}
                    }/>}
                    <Swiper
                        navigation={nav}
                        modules={[Navigation]}
                        slidesPerView={3}
                        className={"teacher__slider"}
                        spaceBetween={20}
                        centeredSlides
                        initialSlide={1}
                    >
                        {
                            teachers.map((teacher, teacherIndex) => {
                                return <SwiperSlide key={teacherIndex}>
                                    <TeachersSlider teacher={teacher} teacherIndex={teacherIndex} />
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                    {len > 1 && <button className="sliders__arrow sliders__arrow-prev__yellow sliders__arrow-next teachers_next"
                                        onClick={() => {
                                            const checker = document.getElementById("read-more-checker_teacher" + index);
                                            if (checker !== null)
                                                (checker as HTMLInputElement).checked = false;
                                            setIndex((prev) => (prev + 1) % len)}
                    }/>}
                </article>
            </section>}
        </>
       
    )
}

export default Teachers;