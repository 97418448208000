import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {getTelegramNick, logoutAction} from '../../store/api-actions';
import {AppRoutes} from '../../const';
import Student from '../../components/student/cabinet/student-cabinet';
import Teacher from '../../components/teacher/cabinet/teacher-cabinet';

function MyAccount(): JSX.Element{
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const roles = useAppSelector((state) => state.roles);
    dispatch(getTelegramNick());
    return (
        <>
            <nav className="navigation__wrapper navigation__wrapper-pc_border">
                <a  className="button button-pc button-xs"
                    aria-label="Ссылка для выхода из профиля"
                    onClick={() => {dispatch(logoutAction()).then(() => {navigate(AppRoutes.Main)})}}>выйти</a>
                <a  className="button button-pc button-xs"
                    aria-label="Ссылка, ведущая на страницу"
                    onClick={() => navigate(AppRoutes.Main)}>на главную</a>
                {roles.find(x => x === 'admin') && <a className="button button-pc button-xs" target="_blank" href={`${window.location.origin}/razoradmin`}>админка</a>}
                <img className="navigation__logo" src={'images/logo_not_name.svg'}
                     alt="Изображение логотипа"/>
            </nav>
            {roles.find(x => x === 'teacher') ? <Teacher/> : <Student/>}
        </>
    );
}

export default MyAccount;