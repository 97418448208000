import * as React from 'react';

function Header(): JSX.Element{
    return(
        <section className="header__wrapper">
            <span className="header__wrapper__info 
                             header__wrapper__h">
                <h1 className="header__h1">Hogmaths</h1>
                <h2 className="header__h2">школа олимпиадной<br/>математики</h2>
            </span>
            <div className="header__button">
                <button
                    onClick={() => (document.getElementById('modal_register') as HTMLDialogElement).showModal()}
                    className="button button-big button-l"
                >
                    записаться
                </button>
            </div>
            <address className="header__wrapper__info header__address">
                <a className="header__address__a" 
                   target="_blank"
                   href="https://yandex.ru/maps/54/yekaterinburg/house/ulitsa_malysheva_36/YkkYcw5mT0wEQFtsfXRycnlgYg==/?ll=60.596693%2C56.833536&z=16">г. Екатеринбург, Малышева 36</a>
                <a className="header__address__a"
                   target="_blank"
                   href="tel:+7 (912) 679 35 37">+7 (912) 679 35 37</a>
            </address>
        </section>
    );
}

export default Header;