import * as React from 'react';

function Properties(): JSX.Element{
    return(
        <section className="properties__wrapper">
            <h1 className="properties__h">Что дает олимпиадная математика?</h1>
            <div className="properties__list__container">
                <ol className="properties__list">
                    <li className="property__item property__item-first">
                        <span className="property__content">
                            <h2 className="property__h">Поступление в вуз без экзаменов</h2>
                            <ul className="property__info"> Диплом перечневой олимпиады даёт возможность получить:
                                <li className="property__info-li">100 баллов по предмету в зачёт ЕГЭ;</li>
                                <li className="property__info-li">самая мощная льгота - БВИ («без вступительных испытаний»)</li>
                            </ul>
                        </span>
                    </li>
                    <li className="property__item property__item-second">
                        <span className="property__content">
                            <h4 className="property__h">Математическая культура</h4>
                            <ul className="property__info">Когда ребенок занимается нестандартной математикой, у него развивается много умений:
                                <li className="property__info-li">анализировать;</li>
                                <li className="property__info-li">думать быстро;</li>
                                <li className="property__info-li">работать с огромной концентрацией;</li>
                                <li className="property__info-li">быстро переключаться между задачами</li>
                            </ul>
                        </span>
                    </li>
                    <li className="property__item property__item-third">
                        <span className="property__content">
                            <h4 className="property__h">Близкое по духу окружение</h4>
                            <p className="property__info">Ничто так не сближает людей, как совместное решение сложных задач или выступление на командных математических турнирах. </p>
                            <p className="property__info">Непосредственное участие в "математической тусовке" по всей стране</p>
                        </span>
                    </li>
                    <li className="property__item property__item-fourth">
                        <span className="property__content">
                            <h4 className="property__h">Фундамент для взрослой жизни</h4>
                            <p className="property__info">Математика останется добрым помощником как в школьные и университетские годы, так и в дальнейшей жизни. </p>
                        </span>
                        <span className="property__content-additional">
                            <p className="property__content__info-additional property__content__info-left">Все призеры заключительного этапа ВСОШ по математике 2021 и 2022 года из Екатеринбурга - наши ученики (8 дипломов)</p>
                            <p className="property__content__info-additional property__content__info-right">50% победителей регионального этапа ВСОШ по математике в 2021 и 2022 году являются нашими учениками</p>
                        </span>
                    </li>
                </ol>
            </div>
        </section>
        
    );
}

export default Properties;