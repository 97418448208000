import * as React from 'react';
import {useAppSelector} from '../../../hooks';
import Bot from "../../bot/bot";

function StudentProfile(): JSX.Element{
    const student = useAppSelector((state) => state.student);
    return (
        <section className="profile__wrapper">
            <div className="profile__person">
                <div className="profile__photo-student">
                    <img className="profile__photo"
                         src={`/images/project_avatars/student${student ? "-"+student.facultyCharacter: ""}.svg`} alt={"Студент"}/>
                    <p className="profile__faculty">{student ? `Факультет ${student.facultyName}` : 'Факультет Римана'}</p>
                </div>
                <span className="profile__person_info">
                    <p className="profile__name">{student ? `${student.fio}` : 'Иванов Иван Иванович'}</p>    
                    <span className="profile__status-student">
                        <p className="profile__status">{student ? `${student.classCharacter} класс` :'5А класс'}</p>
                        <p className={`profile__scores profile__scores-${student ? student.facultyCharacter :'Z'}`}>{`${student ? student.score : 10} баллов`}</p>
                    </span>
                    
                </span>
            </div>
            <Bot />
        </section>
    );
}

export default StudentProfile;