import * as React from 'react';
import {useAppDispatch} from '../../../hooks';
import {postMaterialAction} from '../../../store/api-actions';
import MaterialsModal from './materials-modal';

type Props = {
    classId: number;
};

function AddMaterials({classId}: Props): JSX.Element{


    const dispatch = useAppDispatch();

    const onSubmit = (id: number, name: string, content: string, dropForm: () => void) => {
        dispatch(postMaterialAction({classId, name, content}))
            .then(closeForm)
            .then(dropForm);
    };

    const closeForm = () =>{
        (document.getElementById('modal_add') as HTMLDialogElement).close();
    }
    

    return(
        <dialog className="modal_pc"
                id="modal_add"
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <MaterialsModal
                material={null}
                formName="Добавить новую тему"
                closeForm={closeForm}
                onSubmit={onSubmit}
                addNewOne={true}
            />
        </dialog>
    );
}

export default AddMaterials;