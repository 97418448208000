import * as React from 'react';
import {useEffect, useState} from 'react';
import AddMaterials from "./add-materials";
import EducationalMaterialsBlock from '../../education-materials-block/educational-materials-block';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {getClassesAction} from '../../../store/api-actions';
import {Class} from '../../../types/main-data';

function TeacherMaterials(): JSX.Element{
    const [classId, setClassId] = useState(0); /*показывает класс или нет*/
    const [border, setBorder] = useState("0 15px 15px 0");

    const classes = useAppSelector((state) => state.classes);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getClassesAction())
        },
        [dispatch]
    );

    return (
        <>
            <section className="materials__wrapper">
                <div className="materials__header">
                    <div className="materials__class-selection">
                        <span className="materials__class-description">Класс:</span>
                        <select className="materials__class-list"
                                id="class"
                                name="Класс" 
                                required
                                defaultValue={""}
                                style={{ borderRadius: border }}
                                onBlur={() => {setBorder("0 15px 15px 0")}}
                                onClick={() => {
                                    if (border === "0 15px 0 0") {
                                        setBorder("0 15px 15px 0");
                                    } else {
                                        setBorder("0 15px 0 0");
                                    }
                                }}
                                onChange={(evt) => setClassId(+evt.target.value)}
    >
                            <option className="materials__class-item"
                                    value={""}
                                    disabled hidden></option>
                            {
                                classes.map((c: Class) =>
                                    <option className="materials__class-item"
                                            key={c.id + c.character}
                                            value={c.id}
                                    >
                                        {c.character}
                                    </option>
                                )
                            }
                        </select>
                    </div>
                    <button className={`button button-pc button-materials__add ${classId === 0 && 'button-materials__add-no_active'}`}
                            disabled={classId === 0}
                            onClick={() => (document.getElementById('modal_add') as HTMLDialogElement).showModal()}>
                        <img src="/images/add.svg"
                             alt=""/>Добавить новую тему
                    </button>
                </div>
                <EducationalMaterialsBlock classId={classId}/>
            </section>

            <AddMaterials classId={classId}/>


        </>
    );
}

export default TeacherMaterials;