import * as React from 'react';
import {useRef, useState} from 'react';
import EmptyFieldError from "../empty-field-error/empty-field-error";

function BotModalInfo(): JSX.Element{
    
    const closeForm = () =>{
        (document.getElementById('modal_bot-info') as HTMLDialogElement).close();
    }


    return(
        <dialog className="modal_pc" 
                id="modal_bot-info"
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <article className="modal_pc-wrapper">
                <h2 className="modal_pc__h modal_pc__h-bot">
                    <button type="reset"
                            form="add_materials__reset"
                            className="modal_pc__h-button"
                            onClick={closeForm}>
                        <img src="/images/arrows/black_arrow.svg"
                             alt=""/>
                    </button>
                    Функции Telegram-бота
                </h2>
                <form className="modal_pc__form modal_pc__form-bot">
                    <div className="modal_pc__row">
                        <p className="modal_pc__form_p">Telegram-бот с информацией об учениках и преподавателях.</p>
                        <p className="modal_pc__form_p2">В нем можно найти контакты учеников и преподавателей, чтобы можно было с ними связаться
                            <b/>
                            Так же можно оставить свои контактные данные чтобы тебя могли легко найти другие ученики или преподаватели. </p>
                    </div>

                </form>
            </article>
        </dialog>
    );
}

export default BotModalInfo;