import {getFacultiesAction, getTelegramNick} from '../../../store/api-actions';
import StudentTabs from '../tabs/studentTabs';
import {StudentTab} from '../../../types/student-tab';
import Rating from '../rating/rating';
import StudentMaterials from '../materials/student-materials';
import Schedule from '../../schedule/schedule';
import * as React from 'react';
import {useState} from 'react';
import {useAppDispatch} from '../../../hooks';

function Student(): JSX.Element{
    const [studentTab, setStudentTab] = useState<StudentTab>(StudentTab.Rating);
    const dispatch = useAppDispatch();

    dispatch(getFacultiesAction());
    return (
        <article className="cabinet__wrapper">
            <StudentTabs
                currentTab={studentTab}
                updateTab={(tabName: StudentTab) => {
                    setStudentTab(tabName);
                }}
            />
            {studentTab === StudentTab.Rating && <Rating />}
            {studentTab === StudentTab.Materials && <StudentMaterials />}
            {studentTab === StudentTab.Schedule && <Schedule />}
        </article>
    );
}

export default Student;