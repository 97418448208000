import * as React from 'react';
import {MainPageBlocks} from "../../const";

function SignUp(): JSX.Element{
    return(
        <section className="sign_up__wrapper" id={MainPageBlocks.SignUp}>
            <div className="sign_up__button">
                <button
                    onClick={() => (document.getElementById('modal_register') as HTMLDialogElement).showModal()}
                    className="button button-huge button-accent button-xl"
                >
                    записаться
                </button>
            </div>
        </section>
    );
}

export default SignUp;