import * as React from 'react';
import {ScheduleTab} from '../../types/schedule';
type TabsProps = {
    currentTab: string,
    updateTab: (a: ScheduleTab) => void
};

function ScheduleTabs({currentTab, updateTab}: TabsProps): JSX.Element {
    return (
            <nav className="schedule__day_week">
                <button className={`button button-pc button-schedule__day-week ${currentTab === ScheduleTab.Monday && "button-schedule__day-week-active"}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(ScheduleTab.Monday);}}>{ScheduleTab.Monday}</button>

                <button className={`button button-pc button-schedule__day-week ${currentTab === ScheduleTab.Tuesday && "button-schedule__day-week-active"}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(ScheduleTab.Tuesday);}}>{ScheduleTab.Tuesday}</button>
                <button className={`button button-pc button-schedule__day-week ${currentTab === ScheduleTab.Wednesday && "button-schedule__day-week-active"}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(ScheduleTab.Wednesday);}}>{ScheduleTab.Wednesday}</button>
                <button className={`button button-pc button-schedule__day-week ${currentTab === ScheduleTab.Thursday && "button-schedule__day-week-active"}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(ScheduleTab.Thursday);}}>{ScheduleTab.Thursday}</button>
                <button className={`button button-pc button-schedule__day-week ${currentTab === ScheduleTab.Friday && "button-schedule__day-week-active"}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(ScheduleTab.Friday);}}>{ScheduleTab.Friday}</button>
                <button className={`button button-pc button-schedule__day-week ${currentTab === ScheduleTab.Saturday && "button-schedule__day-week-active"}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(ScheduleTab.Saturday);}}>{ScheduleTab.Saturday}</button>
                <button className={`button button-pc button-schedule__day-week ${currentTab === ScheduleTab.Sunday && "button-schedule__day-week-active"}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(ScheduleTab.Sunday);}}>{ScheduleTab.Sunday}</button>
            </nav>
    );
}

export default ScheduleTabs;