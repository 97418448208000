import {createAction} from '@reduxjs/toolkit';
import {Student, Teacher} from '../types/user-data';
import {Class, Faculty, Material, Review, TeacherPhoto} from '../types/main-data';
import {Schedule} from '../types/schedule';

export const setRoles = createAction<string[]>('user/roles');

export const setStudentInfo = createAction<Student>('student/info');
export const setTeacherInfo = createAction<Teacher>('teacher/info');
export const setTeacherPhotos = createAction<TeacherPhoto[]>('teachers/photos');
export const setSchedule = createAction<Schedule>('schedule');
export const setReviews = createAction<Review[]>('reviews');
export const setPrice = createAction<number>('price');
export const setFaculties = createAction<Faculty[]>('faculties');
export const setMaterials = createAction<Material[]>('materials');
export const setClasses = createAction<Class[]>('classes');
export const setTelegram = createAction<string>('telegram');