import {Lesson} from '../../types/schedule';
import * as React from 'react';

type LessonsRowProps = {
    lessons: (Lesson|null)[];
}

type LessonProps = {
    lesson: Lesson | null
}

function LessonCell({lesson}: LessonProps):JSX.Element{
    if (lesson == null) {
        return <td className="schedule__table_day-td"/>
    }
    return (
        <td className="schedule__table_day-td">
            <span className="schedule__table_day-name">{`${lesson.classCharacter} `}</span>
            <br/>
            <span className="schedule__table_day-time">{ lesson.startTime === '' ? '' : `${lesson.startTime} - ${lesson.endTime}`}</span>
        </td>
    );
}

function LessonsRow({lessons}: LessonsRowProps): JSX.Element{
    return (
        <tr>
            {lessons.map((x, index) => <LessonCell lesson={x} key={index}/>)}
        </tr>
    );
}

export default LessonsRow;