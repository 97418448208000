import * as React from 'react';

function Footer(): JSX.Element{
    return (
        <footer className="footer__wrapper">
            <p>© 2021 Кружок олимпиадной математики "Hogmaths"</p>
            <p>Made by “2B2F”</p>
        </footer>
    );
}

export default Footer;