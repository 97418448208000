import * as React from 'react';
import {TeacherTab} from '../../../types/teacher-tab';
import TeacherProfile from "../teacher-profile/teacher-profile";

type TabsProps = {
    currentTab: string,
    updateTab: (a: TeacherTab) => void
};

function TeacherTabs({currentTab, updateTab}: TabsProps): JSX.Element {
    return (
        <>
            <TeacherProfile />
            <nav className="navigation__wrapper navigation__wrapper-pc">
                <button className={`button button-pc button-pc_nav ${currentTab === TeacherTab.Materials && 'button-pc_nav-active'}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(TeacherTab.Materials);}}>{TeacherTab.Materials}</button>
                <button className={`button button-pc button-pc_nav ${currentTab === TeacherTab.Schedule && 'button-pc_nav-active'}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(TeacherTab.Schedule);}}>{TeacherTab.Schedule}</button>
            </nav>
        </>
    );
}

export default TeacherTabs;