import * as React from 'react';
import {SyntheticEvent, useRef, useState} from 'react';
import {useAppDispatch} from '../../hooks';
import {postRegistrationFormAction} from '../../store/api-actions';
import EmptyFieldError from '../empty-field-error/empty-field-error';

enum SendState {
    Send = 'отправить',
    Sending = 'отправляем...',
    Done = 'готово!'
}

const phone_re_ = new RegExp(/[8|7]9[0-9]{9}/)
const email_re_ = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)

function Register(): JSX.Element {
    const form = useRef(null);
    const dispatch = useAppDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const [buttonText, setButtonText] = useState(SendState.Send);

    const [mail, setMail] = useState<string>('');
    const [parent, setParent] = useState<string>('');
    const [student, setStudent] = useState<string>('');
    const [graduate, setGraduate] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [direction, setDirection] = useState<string>('');
    const [training, setTraining] = useState<string>('');
    const [shift, setShift] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const [isMailInvalid, setIsMailInvalid] = useState<boolean>(false);
    const [isParentEmpty, setIsParentEmpty] = useState<boolean>(false);
    const [isStudentEmpty, setIsStudentEmpty] = useState<boolean>(false);
    const [isGraduateEmpty, setIsGraduateEmpty] = useState<boolean>(false);
    const [isPhoneInvalid, setIsPhoneInvalid] = useState<boolean>(false);
    const [isDirectionEmpty, setIsDirectionEmpty] = useState<boolean>(false);
    const [isTrainingEmpty, setIsTrainingEmpty] = useState<boolean>(false);
    const [isShiftEmpty, setIsShiftEmpty] = useState<boolean>(false);
    const [isCommentEmpty, setIsCommentEmpty] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const closeForm = () =>{
        setIsError(false);
        (document.getElementById('modal_register') as HTMLDialogElement).close()
    }


    const handleSubmit = (evt: SyntheticEvent) =>{
        evt.preventDefault();

        setIsMailInvalid(!email_re_.test(mail));
        setIsParentEmpty(parent == '');
        setIsStudentEmpty(student == '');
        setIsGraduateEmpty(graduate == '');
        setIsPhoneInvalid(!phone_re_.test(phone));
        setIsDirectionEmpty(direction == '');
        setIsTrainingEmpty(training == '');
        setIsShiftEmpty(shift == '');
        setIsCommentEmpty(comment=='');


        if (email_re_.test(mail)
            && parent != ''
            && student != ''
            && graduate != ''
            && phone != ''
            && direction != ''
            && training != ''
            && shift != ''
        ){
            setIsError(false);
            const fd = new FormData(form.current!);
            setIsDisabled(true);
            setButtonText(SendState.Sending);
            dispatch(postRegistrationFormAction(fd))
                .then(()=> {
                    setButtonText(SendState.Done);
                    setTimeout(() => {
                        closeForm();
                        setButtonText(SendState.Send);
                        setIsDisabled(false);
                    }, 2000);
                });
        }
        else { setIsError(true);}
    }

    return (
        <dialog id="modal_register" 
                className="modal-register"
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <article className="form-container form-register" id="authentication">
                <div className="from-register__header">
                    <button type="reset"
                            className="form__return-button"
                            aria-label="Назад"
                            onClick={closeForm}>
                        <img src="/images/arrows/black_arrow.svg"
                             alt=""/>
                    </button>
                    <span className="register-header">
                        Запись на занятия
                    </span>
                </div>

                <form ref={form} onSubmit={handleSubmit} className="form__register-wrapper" id="reg-form">

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="mail">Ваш e-mail</label>
                        <input
                            className={`dialog-field register__dialog-field ${isMailInvalid ? "register-field-error" : ""}`}
                            name="Почта"
                            type="email"
                            id="mail"
                            defaultValue={mail}
                            onChange={e => {setIsMailInvalid(!email_re_.test(e.target.value)); setMail(e.target.value);}}
                            required
                        />
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="name_parent">ФИО родителя</label>
                        <input
                            name="ФИО родителя"
                            id="name_parent"
                            defaultValue={parent}
                            onChange={e => {setIsParentEmpty(false); setParent(e.target.value);}}
                            className={`dialog-field register__dialog-field ${isParentEmpty ? "register-field-error" : ""}`}
                            required
                        />
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="name_child">ФИО ребенка</label>
                        <input
                            className={`dialog-field register__dialog-field ${isStudentEmpty ? "register-field-error" : ""}`}
                            id="name_child"
                            name="ФИО ребенка"
                            defaultValue={student}
                            onChange={e => {setIsStudentEmpty(false); setStudent(e.target.value);}}
                            required
                        />
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="class">Класс</label>
                        <input
                            className={`dialog-field register__dialog-field ${isGraduateEmpty ? "register-field-error" : ""}`}
                            id="class"
                            name="Класс ребенка"
                            defaultValue={graduate}
                            onChange={e => {setIsGraduateEmpty(false); setGraduate(e.target.value);}}
                            required
                        />
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="phone_number">Телефон</label>
                        <input
                            className={`dialog-field register__dialog-field ${isPhoneInvalid ? "register-field-error" : ""}`}
                            type="tel"
                            pattern="(8|\+7|7)9[0-9]{9}"
                            id="phone_number"
                            name="Телефон"
                            defaultValue={phone}
                            onChange={e =>
                            {
                                setPhone(e.target.value);
                            }}
                            required
                        />
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="direction_preparation">Направление подготовки</label>
                        <div className="area-wrapper">
                            <select
                                className={`dialog-field register__dialog-field select-register__dialog-field ${isDirectionEmpty ? "register-field-error" : ""}`}
                                id="direction_preparation"
                                name="Направление подготовки"
                                defaultValue={direction}
                                required
                                onChange={e => {setIsDirectionEmpty(false); setDirection(e.target.value);}}
                            >
                                <option value=""></option>
                                <option value="Классическая олимпиадная математика">Классическая олимпиадная математика
                                </option>
                                <option value="Подготовка к перечневым олимпиадам">Подготовка к перечневым олимпиадам
                                </option>
                            </select>
                        </div>
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="training_information">Учились у нас в прошлом году?</label>
                        <div className="area-wrapper">
                            <select
                                className={`dialog-field register__dialog-field select-register__dialog-field ${isTrainingEmpty ? "register-field-error" : ""}`}
                                id="training_information"
                                name="Уже учились?"
                                required
                                defaultValue={training}
                                onChange={e => {setIsTrainingEmpty(false); setTraining(e.target.value);}}
                            >
                                <option value=""></option>
                                <option value="Да">Да</option>
                                <option value="Нет">Нет</option>
                            </select>
                        </div>
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="information_shift">В какую смену учитесь в школе?</label>
                        <div className="area-wrapper">
                            <select
                                className={`dialog-field register__dialog-field select-register__dialog-field ${isShiftEmpty ? "register-field-error" : ""}`}
                                id="information_shift"
                                name="Смена в школе"
                                required
                                defaultValue={shift}
                                onChange={e => {setIsShiftEmpty(false); setShift(e.target.value);}}
                            >
                                <option value=""></option>
                                <option value="Первая">Первая смена</option>
                                <option value="Вторая">Вторая смена</option>
                            </select>
                        </div>
                    </div>

                    <div className="dialog-row">
                        <label className="register__dialog-label" htmlFor="comment">Комментарий</label>
                        <div className="area-wrapper">
                            <textarea
                                className={`comment-dialog-field ${isCommentEmpty ? "" : "comment-dialog-field-not-empty"} secondary-dialog-field`}
                                id="comment"
                                name="Комментарий"
                                defaultValue={comment}
                                onChange={e => {setIsCommentEmpty(e.target.value == '' || e.target.value == null); setComment(e.target.value);}}
                            >
                            </textarea>
                        </div>
                    </div>
                    <EmptyFieldError
                        isError={isError}
                        fieldValue={"Некоторые обязательные поля не заполнены и/или заполнены неверно"}
                        form={"empty-field__error-register"}
                    />
                    <div className="modal_pc__buttons modal_pc__submit_button">

                        <input
                            className="button button-medium button-m"
                            id="submit-button"
                            type="submit"
                            value={buttonText}
                            disabled={isDisabled}
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </article>
        </dialog>
    );
}

export default Register;