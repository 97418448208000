import * as React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './store';
import {
    checkAuthAction,
    getPriceAction,
    getReviewsAction,
    getScheduleAction,
    getTeachersAction
} from './store/api-actions';
import {BrowserRouter} from 'react-router-dom';

store.dispatch(checkAuthAction());
store.dispatch(getTeachersAction());
store.dispatch(getScheduleAction());
store.dispatch(getReviewsAction());
store.dispatch(getPriceAction());

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>);


