import React from "react";

type Props = {
  place:
    | "fourth_faculty"
    | "second_faculty"
    | "third_faculty"
    | "first_faculty";
  colorVar: string;
  name: string;
  src: string;
};

const Sizes = {
  first_faculty: "240",
  second_faculty: "200",
  third_faculty: "140",
  fourth_faculty: "100",
};

export const FacultyColumn: React.FC<Props> = ({ src, colorVar, name, place }) => {
  const color = `var(--${colorVar})`

  return (
    <article className="faculty-column">
      <svg
        width="117"
        height="35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.5 34.75L116.957 0.437496H0.0432854L58.5 34.75Z"
          style={{ fill: color }}
        />
      </svg>
      <div className="faculty-column__body">
        <svg width="117" height="35" viewBox="0 0 117 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path style={{
            fill: color
          }} d="M58.5 0.265625L116.091 34.9999H0.929309L58.5 0.265625Z" />
        </svg>
        <svg style={{
          background: color
        }} width="117" height={Sizes[place]} viewBox={`0 0 115 ${Sizes[place]}`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H115V295H0V0Z" style={{
            fill: color
          }} />
          <title>
            Столбец для факультета {name}
          </title>
        </svg>
      </div>
      <img className="faculty-column__logo" src={src} alt={`Логотип факультета ${name}`} />
    </article>
  );
};
