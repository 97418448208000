import * as React from 'react';
import SignIn from '../sign-in/sign-in';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../hooks';
import {AppRoutes, AuthorizationStatus, MainPageBlocks} from '../../const';
import {ButtonNavigation} from "./button-navigation";
import NavigationNav from "./navigation_nav-modal";

function Enter():JSX.Element{
    return (
        <>
            <button  className="button button-s button-small"
                     aria-label="Кнопка, открывающая форму для входа"
                     onClick={() => (document.getElementById('modal_sign_in') as HTMLDialogElement).showModal()}>войти</button>
            <SignIn />
        </>
    );
}

function Cabinet():JSX.Element{
    const navigate = useNavigate();

    return (
        <>
            <a  className="button button-s button-small"
                aria-label="Ссылка, ведущая на страницу личного кабинета"
                onClick={() =>  {navigate(AppRoutes.Cabinet);}}>профиль</a>
        </>
    );
}



function Navigation(): JSX.Element{
    const authStatus = useAppSelector((state) => state.authorizationStatus);
    const teachers = useAppSelector((state) => state.teacherPhotos);
    return (
        <>
            <header className="navigation__wrapper navigation__wrapper-lending">
                <img className="navigation__logo navigation__logo-web" src={'images/logo_not_name.svg'} 
                     alt="Изображение логотипа"/>
                <nav className="navigation__wrapper__nav navigation__wrapper__nav-web">
                    <ButtonNavigation ariaLabel={"к описанию школы"} 
                                      scrollId={MainPageBlocks.Advantages} 
                                      children={"о нас"}
                                      click={()=>{}}/>
                    <ButtonNavigation ariaLabel={"к информации о цене"} 
                                      scrollId={MainPageBlocks.TicketZone} 
                                      children={"цены"}
                                      click={()=>{}}/>
                    <ButtonNavigation ariaLabel={"к описанию процесса обучения"}
                                      scrollId={MainPageBlocks.LessonsProcesses} 
                                      children={"обучение"}
                                      click={()=>{}}/>
                    <ButtonNavigation ariaLabel={"к списку направлений"} 
                                      scrollId={MainPageBlocks.TrainingProfiles} 
                                      children={"направления"}
                                      click={()=>{}}/>
                    {teachers.length >=1 &&
                        <ButtonNavigation ariaLabel={"к списку пеподавателей"}
                                          scrollId={MainPageBlocks.Teachers}
                                          children={"преподаватели"}
                                          click={()=>{}}/>}
                    <ButtonNavigation ariaLabel={"к кнопке 'записаться'"}
                                      scrollId={MainPageBlocks.SignUp} 
                                      children={"записаться"}
                                      click={()=>{}}/>
                </nav>
                {authStatus === AuthorizationStatus.Auth ? <Cabinet/> : <Enter/>}
                <button className="navigation__menu"
                        onClick={() => (document.getElementById('modal_nav') as HTMLDialogElement).showModal()}>
                    <img className="navigation__menu-img" 
                         src={'images/menu.svg'} 
                         alt="Меню"/>
                </button>
                <NavigationNav teachers={teachers}/>
            </header>
        </>
    );
}

export default Navigation;