import * as React from 'react';
import {MainPageBlocks} from "../../const";
import ScheduleModal from "../schedule/schedule-modal";

function LessonsProcesses(): JSX.Element{
    return(<>
            <section className="lessons_processes__wrapper"
                     id={MainPageBlocks.LessonsProcesses}>
                <h1 className="lessons_processes__h">Как проходит обучение</h1>
                <span className="lessons_processes__infos">
                    <div className="lessons_processes__info lessons_processes__info-first">
                        <p className="lessons_processes__info-text">Ученики разделены на 4 факультета, между которыми происходит соревнование.</p>
                    </div>
                    <div className="lessons_processes__info lessons_processes__info-second">
                        <p className="lessons_processes__info-text">В группах не больше 16 человек.</p>
                    </div>
                    <div className="lessons_processes__info lessons_processes__info-third">
                        <p className="lessons_processes__info-text">Преподаватель направляет учеников на решения, а не решает за них.</p>
                    </div>
                </span>
                <div className="lessons_processes__button_dis">
                    <div className="lessons_processes__arrow">
                        <img src="/images/arrows/schedule-1_1.svg"
                             alt=""/>
                        <img src="/images/arrows/schedule-1_2.svg"
                             alt=""/>
                        <img src="/images/arrows/schedule-1_3.svg"
                             alt=""/>
                        <img src="/images/arrows/schedule-1_4.svg"
                             alt=""/>
                    </div>
                    <button className="lessons_processes__button button button-l button-big"
                            onClick={() => (document.getElementById('modal_schedule') as HTMLDialogElement).showModal()}>расписание занятий</button>
                    <div className="lessons_processes__arrow">
                        <img src="/images/arrows/schedule-2_1.svg"
                             alt=""/>
                        <img src="/images/arrows/schedule-2_2.svg"
                             alt=""/>
                        <img src="/images/arrows/schedule-2_3.svg"
                             alt=""/>
                    </div>
                </div>
            </section>
            <ScheduleModal></ScheduleModal>
        </>

    );
}

export default LessonsProcesses;