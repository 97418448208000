import {StudentTab} from '../../../types/student-tab';
import * as React from 'react';
import StudentProfile from "../profile/studentProfile";

type TabsProps = {
    currentTab: string,
    updateTab: (a: StudentTab) => void
};

function StudentTabs({currentTab, updateTab}: TabsProps): JSX.Element {
    return (
        <>
            <StudentProfile />
            <nav className="navigation__wrapper navigation__wrapper-pc">
                <button className={`button button-pc button-pc_nav ${currentTab === StudentTab.Rating && 'button-pc_nav-active'}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(StudentTab.Rating);}}>{StudentTab.Rating}</button>
                <button className={`button button-pc button-pc_nav ${currentTab === StudentTab.Materials && 'button-pc_nav-active'}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(StudentTab.Materials);}}>{StudentTab.Materials}</button>
                <button className={`button button-pc button-pc_nav ${currentTab === StudentTab.Schedule && 'button-pc_nav-active'}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            updateTab(StudentTab.Schedule);}}>{StudentTab.Schedule}</button>
            </nav>
        </>
    );
}

export default StudentTabs;