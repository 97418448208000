import * as React from 'react';
import {useAppDispatch} from '../../../hooks';
import {changeMaterialAction} from '../../../store/api-actions';
import {Material} from "../../../types/main-data";
import MaterialsModal from './materials-modal';

type Props = {
    classId: number;
    material: Material | null;
};

function ChangeMaterials({classId, material}: Props): JSX.Element{
    const dispatch = useAppDispatch();
    

    const onSubmit = (id: number, name: string, content: string, dropForm: () => void) => {
        const material: Material = {id, name, content}
        dispatch(changeMaterialAction({material, classId }))
            .then(closeForm);
    };
    
    const closeForm = () =>{
        (document.getElementById('modal_change' + material!.id) as HTMLDialogElement).close()
    }
    

    return(
        <dialog className="modal_pc" 
                id={"modal_change" + material!.id}
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <MaterialsModal
                material={material}
                formName="Редактировать тему"
                closeForm={closeForm}
                onSubmit={onSubmit}
                addNewOne={false}
            />
        </dialog>
    );
}

export default ChangeMaterials;