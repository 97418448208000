import React, {useState} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import {Review} from '../../types/main-data';
import {useAppSelector} from '../../hooks';
import "swiper/css";
import "swiper/css/navigation";
import {ReviewsSlider} from "./reviews_slider";
import {NavigationOptions} from 'swiper/types';

function Reviews(): JSX.Element{
    const reviews: Review[] = useAppSelector((store)=> store.reviews);
    const len = reviews.length;
    const [index, setIndex] = useState(0);
    const nav: NavigationOptions = {
        nextEl: '.reviews_next',
        prevEl: '.reviews_prev',
    }
    return (
        <>
            {reviews.length > 0 && <section className="reviews__wrapper">
                <h1 className="reviews__h">Отзывы о нас</h1>
                <article className="sliders_wrapper">
                    {len > 1 && <button className="sliders__arrow sliders__arrow-prev__white reviews_prev"
                                        onClick={() => {(document.getElementById("read-more-checker_review" + index) as HTMLInputElement).checked = false;
                                            setIndex((prev) => ((len + prev - 1) % len))}}></button>}
                    <Swiper
                        navigation={nav}
                        modules={[Navigation]}
                        className="reviews__swiper"
                        loop
                        spaceBetween={80}
                    >
                        {
                            reviews.map((review, reviewIndex) => {
                                return <SwiperSlide key={reviewIndex}>
                                    <ReviewsSlider review={review} reviewIndex={reviewIndex} />
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                    {len > 1 && <button className="sliders__arrow sliders__arrow-prev__white sliders__arrow-next reviews_next"
                                        onClick={() => {(document.getElementById("read-more-checker_review" + index) as HTMLInputElement).checked = false;
                                            setIndex((prev) => (prev + 1) % len)}}></button>}
                </article>
            </section>}
        </>
    );
}

export default Reviews;