import {AuthorizationStatus, AppRoutes} from '../../const';
import React from 'react';
import { Navigate } from "react-router-dom";

type PrivateRouteProps = {
  authorizationStatus: string;
  children: JSX.Element;
};

function PrivateRoute({authorizationStatus, children}: PrivateRouteProps): JSX.Element {
    return authorizationStatus === AuthorizationStatus.Auth ? children : <Navigate to={AppRoutes.Main} replace/>;
}

export default PrivateRoute;
