import * as React from "react";
import {TeacherPhoto} from "../../types/main-data";

type Props = {
    teacher: TeacherPhoto;
    teacherIndex: number;
};


export function TeachersSlider({teacher, teacherIndex}: Props): JSX.Element{
    return (
        <div className={`slide teacher__slide`}>
            <div className="teacher__person">
                <img className="teacher__photo" src={`data:image/jpeg;base64,${teacher.photoContent}`} alt={teacher.teacherName}/>
                <p className="teacher__name">{teacher.teacherName}</p>
            </div>
            <input type="checkbox" className="read-more-checker" id={"read-more-checker_teacher" + teacherIndex}/>
            <div className="teacher_info limiter">
                <hr className="teacher_separator"></hr>
                <ul className="teacher_info__text">
                    {
                        teacher.teacherInfo.split(/\r?\n/).map((info) =>{
                            return <li className="teacher_info__text-li">{info}</li>
                        })
                    }
                </ul>
            </div>
            <label htmlFor={"read-more-checker_teacher" + teacherIndex} className="read-more-button"></label>
        </div>
    );
}