export enum ScheduleTab {
    Monday = 'Понедельник',
    Tuesday = 'Вторник',
    Thursday = 'Четверг',
    Saturday = 'Суббота',
    Friday = 'Пятница',
    Wednesday = 'Среда',
    Sunday = 'Воскресенье'
}

export type Lesson = {
    classCharacter: string,
    startTime: string,
    endTime: string
}

export type ScheduleClass = {
    [key: string] : (Lesson | null)[];
}

export type Schedule = {
    [key: string] : ScheduleClass;
}

export type IScheduleType = {
    [key: string] : Schedule;
}
