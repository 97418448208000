export enum AuthorizationStatus {
    Auth = 'AUTH',
    NoAuth = 'NO_AUTH',
    Unknown = 'UNKNOWN',
}

export enum AppRoutes {
    Main = '/',
    Cabinet = '/cabinet'
}

export const REGISTRATION_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbyIwR1ubxnASYHhH7BVNfXBDDWbwTUcTSHCsrasnTfDxYP_fvPV7iv5NbNI6ojexgS3bQ/exec'

export enum MainPageBlocks {
    Advantages = 'advantages',
    TicketZone = 'ticket_zone',
    LessonsProcesses = 'lessons_processes_block',
    TrainingProfiles = 'training_profiles',
    Teachers = 'teachers',
    SignUp = 'sign_up',
    
}
