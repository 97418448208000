import React from 'react';

function Contacts(): JSX.Element{
    return (
        <section className="contacts__wrapper">
            <span className="contacts__infos">
                    <address className="contacts__info">
                        <p className="contact__name">Кружок олимпиадной математики "Hogmaths"</p>
                        <a className="contact__address"
                           target="_blank"
                           href="https://yandex.ru/maps/54/yekaterinburg/house/ulitsa_malysheva_36/YkkYcw5mT0wEQFtsfXRycnlgYg==/?ll=60.596693%2C56.833536&z=16">Малышева 36, офисы 218, 216, 424<br/>Екатеринбург, Россия</a>
                    </address>
                    <address className="contact__links">
                        <a className="contact__link"
                           target="_blank"
                           href={"https://vk.com/ekbmathematics"}>
                            <img src={"/images/vk.svg"} alt={""}/>https://vk.com/ekbmathematics</a>
                        <a className="contact__link"
                           target="_blank"
                           href={"mailto:Zoy01111@gmail.com\n"}>
                            <img src={"/images/email.svg"} alt={""}/>Zoy01111@gmail.com</a>
                        <a className="contact__link"
                           target="_blank"
                           href="tel:+7 (912) 679 35 37">
                            <img src={"/images/number.svg"} alt={""}/>+7 (912) 679 35 37</a>
                    </address>
            </span>
            <div className="contact__map" style={{}}>
                <iframe src="https://yandex.ru/map-widget/v1/-/CDWzuBYE"
                        allowFullScreen={true} style={{position:"relative"}} title={'Где нас найти'}></iframe>
            </div>
        </section>
    );
}

export default Contacts;