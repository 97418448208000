import * as React from 'react';
import Navigation from '../../components/navigation/navigation';
import Header from '../../components/header/header';
import Advantages from '../../components/advantages/advantages';
import Properties from '../../components/properties/properties';
import Description from '../../components/description/description'
import TicketZone from "../../components/ticket_zone/ticket_zone";
import LessonsProcesses from "../../components/lessons_processes/lessons_processes";
import TrainingProfiles from '../../components/training_profiles/training_profiles';
import Teachers from '../../components/teachers/teachers';
import SignUp from "../../components/sign_up/sign_up";
import Footer from '../../components/footer/footer';
import Contacts from '../../components/contacts/contacts';
import Reviews from '../../components/reviews/reviews';
import Register from '../../components/regisrty/register';

function MainPage(): JSX.Element{

    return (
        <>
                <Navigation />
                <Header />
                <Advantages />
                <Properties />
                <Description />
                <TicketZone />
                <LessonsProcesses />
                <Reviews/>
                <TrainingProfiles/>
                <Teachers/>
                <SignUp />
                <Register/>
                <Contacts/>
                <Footer/>
        </>
    );
}

export default MainPage;
