import EducationalMaterial from '../educational-material/educational-material';
import * as React from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {useEffect} from 'react';
import {getMaterialsAction} from '../../store/api-actions';
import {Material} from '../../types/main-data';

type Props = {
    classId: number;
}
function EducationalMaterialsBlock({classId}:Props ): JSX.Element{
    const materials = useAppSelector((state) => state.materials);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (classId !== 0) {
            dispatch(getMaterialsAction(classId))
        }},
        [classId, dispatch]
    );
    return (
        <div className="">
            {materials.map((material: Material, index: number) =>
                <EducationalMaterial
                    material={material}
                    classId={classId}
                    key={index}
                />)}
        </div>
    );
}

export default EducationalMaterialsBlock;