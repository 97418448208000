import * as React from 'react';
import {useAppDispatch} from '../../../hooks';
import {deleteMaterialAction} from '../../../store/api-actions';
import {Material} from "../../../types/main-data";

type Props = {
    classId: number;
    material: Material;
};

function DelMaterials({classId, material}: Props): JSX.Element{
    const dispatch = useAppDispatch();

    const onSubmit = (material: Material, classId: number) => {
        dispatch(deleteMaterialAction({material, classId}))
            .then(closeForm);
    };
    
    const closeForm = () =>{
        (document.getElementById('modal_del' + material.id)! as HTMLDialogElement).close();
    }
    

    return(
        <dialog className="modal_pc" 
                id={"modal_del" + material.id}
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <article className="modal_pc-wrapper">
                <h2 className="modal_pc__h">
                    <button type="reset"
                            form="add_materials__reset"
                            className="modal_pc__h-button"
                            onClick={closeForm}>
                        <img src="/images/arrows/black_arrow.svg"
                             alt=""/>
                    </button>
                    Удалить
                </h2>
                <form className="modal_pc__form">
                    <div className="modal_pc__row">
                        <p className="modal_pc__form_p-del">Вы уверены, что хотите удалить <mark className="modal_pc__form_p-name">{material.name} </mark></p>
                    </div>

                    <div className="modal_pc__buttons modal_pc__submit_button">
                        <input
                            className="button button-pc button-materials button-m"
                            id="submit-button"
                            type="submit"
                            value="Удалить"
                            onClick={(evt) => {
                                evt.preventDefault();
                                onSubmit(material, classId);
                            }}
                        />
                    </div>

                </form>
            </article>
        </dialog>
    );
}

export default DelMaterials;