import * as React from 'react';
import {useAppSelector} from '../../../hooks';
import Bot from "../../bot/bot";

function TeacherProfile(): JSX.Element{
    const teacher = useAppSelector((state) => state.teacher);
    return (
        <section className="profile__wrapper">
            <div className="profile__person">
                <img className="profile__photo" 
                     src='/images/project_avatars/teacher.svg' alt={"Учитель"}/>
                <span className="profile__person_info">
                    <p className="profile__name">{teacher ? `${teacher.fio}` : 'Иванов Иван Иванович'}</p>    
                    <p className="profile__status">Учитель</p>
                </span>
            </div>
            <Bot/>
                           
            
        </section>
    );
}

export default TeacherProfile;