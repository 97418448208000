import * as React from 'react';
import {Material} from '../../types/main-data';
import ChangeMaterials from "../teacher/materials/change-materials";
import DelMaterials from "../teacher/materials/del-materials";
import {getMaterialAction} from '../../store/api-actions';
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch} from '../../hooks';
type Props = {
    classId: number;
    material: Material;
}

function EducationalMaterial({classId, material}:Props): JSX.Element{
    const dispatch = useAppDispatch();

    const getMaterialHref = (materialId: number): Promise<string> => {
        return new Promise((resolve, reject) => {
            dispatch(getMaterialAction(materialId)).then(unwrapResult).then((material) => {
                resolve(`data:application/pdf;base64,${material.content}`);
            }).catch(reject);
        });
    }

    return (
        <>
            <div className="material">
                <a className="material__a"
                   href="#"
                   onClick={(event) => {
                       event.preventDefault();
                       getMaterialHref(material.id).then((href) => {
                           const link = document.createElement('a');
                           link.href = href;
                           link.download = `${material.name}.pdf`;
                           link.dispatchEvent(new MouseEvent('click'));
                       });
                   }}>
                    {material.name}
                </a>
                <button className="material__button-change"
                        onClick={() => (document.getElementById('modal_change' + material.id) as HTMLDialogElement).showModal()}>Редактировать</button>
                <button className="material__button-del" 
                        onClick={() => (document.getElementById('modal_del' + material.id) as HTMLDialogElement).showModal()}>
                    <img src="/images/del.svg"
                         alt=""/>
                </button>
            </div>
            <ChangeMaterials classId={classId} material={material}/>
            <DelMaterials classId={classId} material={material}/>
        </>
    );
}

export default EducationalMaterial;