import {createReducer} from '@reduxjs/toolkit';
import {AuthorizationStatus} from '../const';
import {checkAuthAction, getCurrentStudentAction, loginAction, logoutAction} from './api-actions';
import {
    setClasses,
    setFaculties, setMaterials,
    setPrice,
    setReviews,
    setRoles,
    setSchedule,
    setStudentInfo,
    setTeacherInfo,
    setTeacherPhotos,
    setTelegram
} from './action';
import {Student, Teacher} from '../types/user-data';
import {Class, Faculty, Material, Review, TeacherPhoto} from '../types/main-data';
import {Schedule} from '../types/schedule';



type InitialState = {
  authorizationStatus: string;
  roles: string[];
  student: Student | null;
  teacher: Teacher | null;
  teacherPhotos: TeacherPhoto[];
  schedule: Schedule | null;
  reviews: Review[];
  price: number;
  faculties: Faculty[];
  materials: Material[];
  classes: Class[];
  telegramNick: string | null;
}

const initialState: InitialState = {
  authorizationStatus: AuthorizationStatus.Unknown,
  roles: [],
  student: null,
  teacher: null,
  teacherPhotos: [],
  schedule: null,
  reviews: [],
  price: 500,
  faculties: [],
  materials: [],
  classes: [],
  telegramNick: null
};



export const reducer = createReducer(initialState, (builder) => {
    builder
      .addCase(loginAction.fulfilled, (state: InitialState) => {
        state.authorizationStatus = AuthorizationStatus.Auth;
      })
      .addCase(loginAction.rejected, (state: InitialState) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth;
      })
      .addCase(checkAuthAction.fulfilled, (state: InitialState) => {
          state.authorizationStatus = AuthorizationStatus.Auth;
      })
      .addCase(checkAuthAction.rejected, (state: InitialState) => {
          state.authorizationStatus = AuthorizationStatus.NoAuth;
      })
      .addCase(logoutAction.fulfilled, (state: InitialState) => {
          state.authorizationStatus = AuthorizationStatus.NoAuth;
      })
      .addCase(getCurrentStudentAction.rejected, (state: InitialState) =>{
          state.student = null;
      })
      .addCase(setRoles, (state: InitialState, action) => {
          state.roles = action.payload;
      })
      .addCase(setStudentInfo, (state: InitialState, action) => {
          state.student = action.payload;
      })
      .addCase(setTeacherInfo, (state: InitialState, action) => {
          state.teacher = action.payload;
      })
      .addCase(setTeacherPhotos, (state: InitialState, action) => {
          state.teacherPhotos = action.payload;
      })
      .addCase(setSchedule, (state: InitialState, action) => {
         state.schedule = action.payload;
      })
      .addCase(setReviews, (state: InitialState, action) => {
          state.reviews = action.payload;
      })
      .addCase(setPrice, (state: InitialState, action) => {
          state.price = action.payload;
      })
      .addCase(setFaculties, (state: InitialState, action) => {
          state.faculties = action.payload;
      })
      .addCase(setMaterials, (state: InitialState, action) => {
          state.materials = action.payload;
      })
      .addCase(setClasses, (state: InitialState, action) => {
          state.classes = action.payload;
      })
        .addCase(setTelegram, (state: InitialState, action) => {
            state.telegramNick = action.payload;
        })
});

