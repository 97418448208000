import * as React from 'react';

type Props = {
    isError: boolean;
    fieldValue: string;
    form: string
};

function EmptyFieldError({isError, fieldValue, form}: Props): JSX.Element{
    return(
        <span className={"empty-field__error " + form} style={{visibility: isError ? "visible" : "collapse"}}>
            {fieldValue}
        </span>
    )
}

export default EmptyFieldError;