import * as React from 'react';
import {useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {useNavigate} from 'react-router-dom';
import {checkAuthAction, loginAction} from '../../store/api-actions';
import {AuthorizationStatus, AppRoutes} from '../../const';
import {unwrapResult} from '@reduxjs/toolkit';
import EmptyFieldError from '../empty-field-error/empty-field-error';

function SignIn(): JSX.Element{
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [isLoginError, setIsLoginError] = useState<boolean>(false);
    const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
    const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

    const [isSuccesses, setIsSuccesses] = useState(true);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authStatus = useAppSelector((state) => state.authorizationStatus);

    const closeForm = () =>{
        (document.getElementById('modal_sign_in') as HTMLDialogElement).close()
    }

    const handleSubmit = async function (e: React.ChangeEvent<any>) {
        e.preventDefault();

        setIsLoginError(login == '');
        setIsPasswordError(password == '');
        if (login != '' && password != '') {
            dispatch(loginAction({
                login,
                password
            }))
                .then(unwrapResult)
                .then(() => {
                    dispatch(checkAuthAction())
                        .then(unwrapResult)
                        .then(() => {
                            if (authStatus === AuthorizationStatus.Auth) {
                                navigate(AppRoutes.Cabinet);
                            }
                        })
                })
                .catch(() => {
                    setIsSuccesses(false);
                    setTimeout(() => setIsSuccesses(true), 3000);
                });
        }
    };

    return (
        <dialog className="modal-sign-in" 
                id="modal_sign_in" 
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <div className="form-container form-login">
                <div className="form-wrapper">
                    <button type="reset"
                            className="form__return-button"
                            aria-label="Назад"
                            onClick={closeForm}>
                        <img src="/images/arrows/black_arrow.svg"
                             alt=""/>
                    </button>
                    <h1 className="login-header">
                        Вход
                    </h1>
                    <form className="form__login-dialog">
                        <div className="login-fields">
                            <div className="login-field">
                                <input
                                    className={`dialog-field login__dialog-field ${isLoginError ? "login-field-error" : ""}`}
                                    type="text"
                                    id="login"
                                    onChange={e => {setIsLoginError(false); setLogin(e.target.value);}}
                                    placeholder="Логин"
                                    required
                                />
                                <EmptyFieldError isError={isLoginError} fieldValue={"Необходимо заполнить поле"} form={"empty-field__error-login"}/>
                            </div>
                            <div className="login-field">
                                <input
                                    className={`dialog-field login__dialog-field ${isPasswordError ? "login-field-error" : "password-dialog-field"}`}
                                    type={isPasswordHidden ? "password" : "text"}
                                    id="password"
                                    onChange={e => {setIsPasswordError(false); setPassword(e.target.value);}}
                                    placeholder="Пароль"
                                    required
                                />
{/*                                    {password != '' && <input
                                    className={isPasswordHidden ? "password-eye password-hidden" : "password-eye password-not-hidden"}
                                    checked
                                />}*/}
                                <EmptyFieldError isError={isPasswordError} fieldValue={"Необходимо заполнить поле"} form={"empty-field__error-login"}/>
                            </div>
                            <EmptyFieldError isError={!isSuccesses} fieldValue={"Мы не смогли вас узнать. Пожалуйста, перепроверьте логин и пароль и попробуйте ещё раз"} form={"empty-field__error-login login-field"}/>
                        </div>

                        <div className="modal_pc__buttons modal_pc__submit_button">
                            <input
                                className="form-login__submit button button-medium button-m"
                                id="submit-button"
                                type="submit"
                                value="Войти"
                                onClick={handleSubmit}
                            />
                        </div>
                        
                    </form>
                </div>
                <figure className="form__preview"/>
            </div>
        </dialog>
    );
}

export default SignIn;