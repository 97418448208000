import * as React from 'react';
import {useRef, useState} from 'react';
import EmptyFieldError from "../../empty-field-error/empty-field-error";
import {Material} from "../../../types/main-data";

type Props = {
    material: Material | null;
    formName: string;
    closeForm: () => void;
    onSubmit: (id: number, name: string, content: string, dropForm: () => void) => void;
    addNewOne: boolean;
};

function MaterialsModal({
                            material,
                            formName,
                            closeForm,
                            onSubmit,
                            addNewOne}
                            : Props)
    : JSX.Element{
    const [name, setName] = useState<string|null>(material?.name !== undefined ? material!.name : null);
    const pdfRef = useRef<HTMLInputElement | null>(null);
    const [button, setButton] = useState(material?.name ?? "Загрузить с устройства");

    const [isNameRefEmpty, setIsNameRefEmpty] = useState<boolean>(false);
    const [isPdfRefEmpty, setIsPdfRefEmpty] = useState<boolean>(false);
    const [isPdfRefChange, setIsPdfRefChange] = useState<boolean>(material?.name !== undefined);

    const dropForm = () =>{
        setName(null);
        pdfRef.current!.value = "";

        setButton("Загрузить с устройства");
        setIsNameRefEmpty(false);
        setIsPdfRefEmpty(false);
        setIsPdfRefChange(false);
    }

    return(
        <article className="modal_pc-wrapper">
            <h2 className="modal_pc__h">
                <button type="reset"
                        form="change_materials__reset"
                        className="modal_pc__h-button"
                        onClick={() => { closeForm(); if (addNewOne) {dropForm();} }
                }>
                    <img src="/images/arrows/black_arrow.svg"
                         alt=""/>
                </button>
                {formName}
            </h2>
            <form className="modal_pc__form"
                  id="change_materials__reset">
                <div className="modal_pc__row">
                    <p className="modal_pc__form_p">Название темы:</p>
                    <input
                        type="text"
                        className={`modal_pc__input modal_pc__input-materials ${isNameRefEmpty ? "modal_pc__error" : ""}`}
                        value={name ?? ''}
                        required
                        onChange={(e)=> {setName(e.target.value); setIsNameRefEmpty(false)}}
                    />
                    <EmptyFieldError form={"empty-field__error-materials"} isError={isNameRefEmpty} fieldValue={"Необходимо заполнить поле"}/>
                </div>
                <div className="modal_pc__row">
                    <p className="modal_pc__form_p">Файл с темой:</p>
                    <div className={`${!isPdfRefChange ? `modal_pc__input`: `material`} ${isPdfRefEmpty ? "modal_pc__error" : ""}`}>
                        <label htmlFor={"name-file-material " + material?.id}
                               className={`material__button-add material__a`}>
                            <img src="/images/add_first.svg"
                                 alt=""
                                 style={{display: isPdfRefChange ? "none" : "block"}}/>
                            {button}
                        </label>
                        <button className="material__button-del"
                                type="button"
                                style={{display: isPdfRefChange ? "flex" : "none"}}
                                onClick={() => {
                                    setButton("Загрузить с устройства")
                                    setIsPdfRefChange(false)}}>
                            <img src="/images/del.svg"
                                 alt=""/>
                        </button>
                        <input
                            type="file"
                            className="modal_pc__input-none"
                            id={"name-file-material " + material?.id}
                            ref={pdfRef}
                            accept=".pdf"
                            required
                            onChange={(evt) => {
                                setIsPdfRefEmpty(evt.target.files!.length === 0)
                                setButton(evt.target.files![0].name)
                                setIsPdfRefChange(true)
                            }
                            }
                        />
                    </div>

                    <EmptyFieldError form={"empty-field__error-materials"} isError={isPdfRefEmpty} fieldValue={"Необходимо заполнить поле"}/>
                </div>

                <div className="modal_pc__buttons modal_pc__submit_button">
                    <input
                        className="button button-pc button-materials button-m"
                        id="submit-button"
                        type="submit"
                        value="Сохранить"
                        onClick={(evt) => {
                            evt.preventDefault();
                            if (pdfRef.current?.files?.length !== 0 && name !== '' && name !== null) {
                                const file = pdfRef.current!.files!.item(0);
                                const reader = new FileReader();
                                reader.readAsBinaryString(file!);
                                reader.onload = function() {
                                    const res = btoa(reader.result as string);
                                    onSubmit(material?.id ?? 0, name, res, dropForm);
                                };
                            }
                            else if (pdfRef.current?.files?.length == 0 && material && name !== '' && name !== null){
                                closeForm();
                            }
                            else {
                                setIsNameRefEmpty(name === '' || name === null)
                                setIsPdfRefEmpty(pdfRef.current?.files?.length == 0)
                            }
                        }}
                    />
                </div>
            </form>
        </article>
    );
}

export default MaterialsModal;