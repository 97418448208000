import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import MainPage from './pages/main-page/main-page';
import MyAccount from './pages/my-account/my-account';
import {useAppSelector} from './hooks';
import PrivateRoute from './components/private-route/private-route';
import {AuthorizationStatus, AppRoutes} from './const';


function App(): JSX.Element {
    const authorizationStatus = useAppSelector((state) => state.authorizationStatus);
    if ( authorizationStatus === AuthorizationStatus.Unknown)
    {
        return (<></>);
    }
    return (
        <Routes>
            <Route index path={AppRoutes.Main} element={<MainPage/>}/>
            <Route
                path={AppRoutes.Cabinet}
                element={
                    <PrivateRoute authorizationStatus={authorizationStatus}>
                        <MyAccount/>
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

export default App;