import * as React from "react";

import {Review} from "../../types/main-data";

type Props = {
    review: Review;
    reviewIndex: number;
};


export function ReviewsSlider({review, reviewIndex}: Props): JSX.Element{
    return (
        <div className={`slide review__slide`}>
            <div className="review__person">
                <img className="review__photo" src={`data:image/jpeg;base64,${review.content}`} alt={review.name}/>
                <span className="review__person_info">
                    <p className="review__name">{review.name}</p>
                    <p className="review__status">{review.info}</p>
                </span>
            </div>
            <input type="checkbox" className="read-more-checker" id={"read-more-checker_review" + reviewIndex}/>
            <div className="review limiter">
                <p className="review__text">{review.text}</p>
            </div>
            <label htmlFor={"read-more-checker_review" + reviewIndex} className="read-more-button"></label>
        </div>
    );
}
