import * as React from 'react';
import {useRef, useState} from 'react';
import EmptyFieldError from "../empty-field-error/empty-field-error";

function BotModalInfoTg(): JSX.Element{
    
    const closeForm = () =>{
        (document.getElementById('modal_bot-info_tg') as HTMLDialogElement).close();
    }


    return(
        <dialog className="modal_pc"
                id="modal_bot-info_tg"
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <article className="modal_pc-wrapper">
                <h2 className="modal_pc__h modal_pc__h-bot">
                    <button type="reset"
                            form="add_materials__reset"
                            className="modal_pc__h-button"
                            onClick={closeForm}>
                        <img src="/images/arrows/black_arrow.svg"
                             alt=""/>
                    </button>
                    Где найти ник в Telegram
                </h2>
                <form className="modal_pc__form modal_pc__form-bot">
                    <ol className="modal_pc__row">
                        <li className="modal_pc__form_p">Зарегестрироваться в Telegram</li>
                        <li className="modal_pc__form_p">Нажать на меню в левом верхнем углу экрана</li>
                        <li className="modal_pc__form_p">Нажать на пункт меню “Настройки”</li>
                        <li className="modal_pc__form_p">В верхней части экрана под номером телефона будет написан ваш ник в Telegram</li>
                    </ol>
                </form>
            </article>
        </dialog>
    );
}

export default BotModalInfoTg;