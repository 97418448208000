import {createAsyncThunk} from '@reduxjs/toolkit';
import {AuthData} from '../types/auth-data';
import {AppDispatch, State} from '../types/state';
import {AxiosInstance} from 'axios';
import {UserData, Student, Teacher} from '../types/user-data';
import {
    setClasses,
    setFaculties, setMaterials,
    setPrice,
    setReviews,
    setRoles,
    setSchedule,
    setStudentInfo,
    setTeacherInfo,
    setTeacherPhotos, setTelegram
} from './action';
import {TeacherPhoto, Review, Faculty, Material, Class} from '../types/main-data';
import {IScheduleType} from '../types/schedule';

import {REGISTRATION_SCRIPT_URL} from '../const';

export const loginAction = createAsyncThunk<void, AuthData, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'user/login',
    async ({login, password}: AuthData, {dispatch, extra: api}) => {
        await api.post('api/user/login', {login, password});
    }
);

export const logoutAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'user/logout',
    async (_arg, {dispatch, extra: api}) => {
        await api.post('api/user/logout');
    }
);

export const checkAuthAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'user/checkAuth',
    async (_arg, {dispatch, extra: api}) => {
        const {data:{roles}} = await api.get<UserData>('api/user/whoAmI');
        dispatch(setRoles(roles));
        if (roles.find(x => x === 'student') ){
            dispatch(getCurrentStudentAction());
        } else {
            dispatch(getCurrentTeacherAction());
        }
    },
);

export const getCurrentStudentAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'user/getCurrentStudentAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<Student>('api/students/me');
        dispatch(setStudentInfo(data));
    },
);

export const getCurrentTeacherAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'user/getCurrentTeacherAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<Teacher>('api/teachers/me');
        dispatch(setTeacherInfo(data));
    },
);

export const getScheduleAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getScheduleAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<IScheduleType>('/api/schedule');
        dispatch(setSchedule(data.schedule));
    },
);

export const getTeachersAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getTeachersAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<TeacherPhoto[]>('api/photos/teachers');
        dispatch(setTeacherPhotos(data));
    },
);

export const getReviewsAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getReviewsAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<Review[]>('api/reviews');
        dispatch(setReviews(data));
    },
);

export const getPriceAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getPriceAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<number>('api/price');
        dispatch(setPrice(data));
    },
);

export const getFacultiesAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getFacultiesAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<Faculty[]>('api/faculties');
        dispatch(setFaculties(data));
    },
);

export const getMaterialsAction = createAsyncThunk<void, number, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getMaterialsAction',
    async (classId, {dispatch, extra: api}) => {
        const {data} = await api.get<Material[]>(`api/classes/${classId}/materials`);
        dispatch(setMaterials(data));
    },
);

export const getClassesAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getClassesAction',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<Class[]>('api/classes');
        dispatch(setClasses(data));
    },
);

export const postMaterialAction = createAsyncThunk<void, {classId: number, name: string, content: string}, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/postMaterialAction',
    async ({classId, name, content}, {dispatch, extra: api}) => {
        await api.post(`api/classes/${classId}/materials`, {
            name: name,
            content: content
        });
        dispatch(getMaterialsAction(classId));
    },
);

export const getMaterialAction = createAsyncThunk<Material, number, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/postMaterialAction',
    async (materialId, {dispatch, extra: api}) => {
        const {data} = await api.get<Material>(`api/materials/${materialId}`);
        return data;
    },
);

export const deleteMaterialAction = createAsyncThunk<void, {material: Material, classId: number}, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/deleteMaterialAction',
    async ({material, classId}, {dispatch, extra: api}) => {
        await api.delete(`api/materials/${material.id}`);
        dispatch(getMaterialsAction(classId));
    },
);

export const changeMaterialAction = createAsyncThunk<void, {material: Material, classId: number}, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/changeMaterialAction',
    async ({material, classId}, {dispatch, extra: api}) => {
        await api.patch(`api/materials/${material.id}`, {
            newName: material.name,
            newContent: material.content
        });
        dispatch(getMaterialsAction(classId));
    },
);

export const postRegistrationFormAction = createAsyncThunk(
    'postRegistrationFormAction',
    async (fd: FormData, {dispatch, extra: api}) => {
        await fetch(REGISTRATION_SCRIPT_URL, {
            method: 'POST',
            body: fd
        });
    },
);

export const getTelegramNick = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/getTelegramNick',
    async (_arg, {dispatch, extra: api}) => {
        const {data} = await api.get<string>(`api/telegram`);
        dispatch(setTelegram(data));
    },
);

export const postTelegramNick = createAsyncThunk<void, {nick: string}, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'main/postMaterialAction',
    async ({nick}, {dispatch, extra: api}) => {
        await api.post(`api/telegram`, {
            telegramNickname: nick
        });
        dispatch(getTelegramNick());
    },
);