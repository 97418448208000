import { Faculty } from "../types/main-data";

export function getPlace(
  char: string,
  faculties: Faculty[]
): "fourth_faculty" | "second_faculty" | "third_faculty" | "first_faculty" {
  const arrayForSort = [...faculties];
  const place = arrayForSort
    .sort((a, b) => b.score - a.score)
    .findIndex((f) => f.character === char);
  if (place === 0) return "first_faculty";
  if (place === 1) return "second_faculty";
  if (place === 2) return "third_faculty";
  return "fourth_faculty";
}
