import * as React from "react";

type Props = {
    information: string;
    color: string;
};


export function DescriptionCard({information, color}: Props): JSX.Element{
    return (
            <span className={"description__card " + color}>
                <p className="description__card__info">{information}</p>
            </span>
    );
}