import * as React from 'react';
import {useRef, useState} from 'react';
import EmptyFieldError from "../empty-field-error/empty-field-error";
import {postTelegramNick} from '../../store/api-actions';
import {useAppDispatch} from '../../hooks';

function BotModalFirst(): JSX.Element{
    const [nick, setNick] = useState<string|null>(null);
    const [consent, setConsent] = useState<boolean>(false);
    
    const [isNickRefEmpty, setIsNickRefEmpty] = useState<boolean>(false);
    const [isConsentRefEmpty, setIsConsentRefEmpty] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const closeForm = () =>{
        setNick(null);
        setConsent(false);
        
        setIsConsentRefEmpty(false);
        setIsNickRefEmpty(false);

        (document.getElementById('modal_bot-first') as HTMLDialogElement).close();
    }


    const handleSubmit = async function (e: React.ChangeEvent<any>) {
        e.preventDefault();
        if (nick !== "" && nick && consent) {
            dispatch(postTelegramNick({nick}));
            closeForm();
        }
        else {
            setIsNickRefEmpty(nick === "" || nick === null)
            setIsConsentRefEmpty(!consent)
        }
    };


    return(
        <dialog className="modal_pc" 
                id="modal_bot-first"
                onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <article className="modal_pc-wrapper">
                <h2 className="modal_pc__h modal_pc__h-bot modal_pc__h-bot-first">
                    О новых функциях личного кабинета
                </h2>
                <form className="modal_pc__form modal_pc__form-bot">
                    <div className="modal_pc__row">
                        <p className="modal_pc__form_p">Telegram-бот с информацией об учениках и преподавателях.</p>
                        <p className="modal_pc__form_p2">В нем можно найти контакты учеников и преподавателей, чтобы можно было с ними связаться
                            <b/>
                            Так же можно оставить свои контактные данные чтобы тебя могли легко найти другие ученики или преподаватели. </p>
                    </div>
                    <div className="modal_pc__row">
                        <p className="modal_pc__form_p">Чтобы открыть новые функции, введи свой ник в Telegram:</p>
                        <input
                            type="text"
                            className={`modal_pc__input modal_pc__form_p2 ${isNickRefEmpty ? "modal_pc__error" : ""}`}
                            required
                            onChange={e => {setIsNickRefEmpty(false); setNick(e.target.value);}}
                        />
                        <EmptyFieldError isError={isNickRefEmpty} fieldValue={"Необходимо заполнить поле"} form={"empty-field__error-tg"}/>
                        <button className="bot__button"
                                onClick={() => (document.getElementById('modal_bot-info_tg') as HTMLDialogElement).showModal()}>
                            Где найти ник в Telegram?</button>
                    </div>
                    <div className="modal_pc__row">
                        <div className="modal_pc__row-checkbox">
                            <input
                                type="checkbox"
                                id="checkbox-first"
                                className={`modal_pc__checkbox ${isConsentRefEmpty ? "modal_pc__error" : ""}`}
                                required
                                onChange={e => {setIsConsentRefEmpty(false); setConsent(e.target.checked);}}
                            />
                            <label htmlFor="checkbox-first"
                                   className="modal_pc__form_p modal_pc__checkbox-input">
                                Я даю согласие на обработку персональных данных
                            </label>
                        </div>
                        <EmptyFieldError isError={isConsentRefEmpty} fieldValue={"Необходимо заполнить поле"} form={"empty-field__error-tg"}/>
                    </div>

                    <div className="modal_pc__buttons modal_pc__submit_button">
                        <button type="reset"
                                form="modal_bot__reset"
                                className="button button-pc button-m"
                                onClick={closeForm}>
                            Заполню позже
                        </button>
                        <input
                            className="button button-pc button-m"
                            id="submit-button"
                            type="submit"
                            value="Добавить"
                            onClick={handleSubmit}
                        />
                    </div>

                </form>
            </article>
        </dialog>
    );
}

export default BotModalFirst;