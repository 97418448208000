import * as React from "react";

type Props = {
    ariaLabel: string;
    scrollId: string;
    children: string;
    click: () => void;
};


export function ButtonNavigation({ariaLabel, scrollId, children, click}: Props): JSX.Element{
    return (
        <button className="navigation__nav"
    aria-label= {'Навигационная кнопка для перемещения по странице'+ariaLabel}
    onClick={() => {Scroll(scrollId);
                    click()}}>{children}</button>
);
}

function Scroll(id:string) {
    document.getElementById(id)!
        .scrollIntoView({block: 'center',
            behavior: 'smooth'})
}