import * as React from 'react';
import {useRef, useState} from 'react';
import {ButtonNavigation} from "./button-navigation";
import {MainPageBlocks} from "../../const";
import {TeacherPhoto} from "../../types/main-data";

type Props = {
    teachers: TeacherPhoto[];
};

function NavigationNav({teachers}: Props): JSX.Element{


    const closeForm = () =>{
        (document.getElementById('modal_nav') as HTMLDialogElement).close();
    }


    return(
        <dialog className="modal_pc modal_menu" 
                id="modal_nav"
            onClick={(e) => e.target == e.currentTarget? closeForm(): {}}>
            <div className="wrapper_menu">
                <div className="navigation__h">
                    <img className="navigation__logo"
                         src={'images/logo_not_name.svg'}
                         alt="Изображение логотипа"/>
                    <button className="navigation__end"
                            onClick={() => closeForm()} >
                        <img className="navigation__end-img"
                             src={'images/close.svg'}
                             alt="Закрытие меню"/>
                    </button>
                </div>
                <nav className="navigation__wrapper__nav">
                    <ButtonNavigation ariaLabel={"к описанию школы"} 
                                      scrollId={MainPageBlocks.Advantages} 
                                      children={"о нас"} 
                                      click={() => closeForm()}/>
                    <ButtonNavigation ariaLabel={"к информации о цене"} 
                                      scrollId={MainPageBlocks.TicketZone} 
                                      children={"цены"}
                                      click={() => closeForm()}/>
                    <ButtonNavigation ariaLabel={"к описанию процесса обучения"}
                                      scrollId={MainPageBlocks.LessonsProcesses} 
                                      children={"обучение"}
                                      click={() => closeForm()}/>
                    <ButtonNavigation ariaLabel={"к списку направлений"} 
                                      scrollId={MainPageBlocks.TrainingProfiles} 
                                      children={"направления"}
                                      click={() => closeForm()}/>
                    {teachers.length >=1 &&
                        <ButtonNavigation ariaLabel={"к списку пеподавателей"}
                                          scrollId={MainPageBlocks.Teachers} 
                                          children={"преподаватели"}
                                          click={() => closeForm()}/>}
                    <ButtonNavigation ariaLabel={"к кнопке 'записаться'"} 
                                      scrollId={MainPageBlocks.SignUp} 
                                      children={"записаться"}
                                      click={() => closeForm()}/>
                </nav>
            </div>
        </dialog>
    );
}

export default NavigationNav;