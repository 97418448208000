import * as React from 'react';
import BotModalFirst from "./bot-modal-first";
import BotModalInfo from "./bot-modal-info";
import BotModalInfoTg from "./bot-modal-info-tg";
import BotModalChange from "./bot-modal-change";
import {useAppDispatch, useAppSelector} from '../../hooks';
import {getFacultiesAction, getTelegramNick} from '../../store/api-actions';

function Bot(): JSX.Element{
    const telegramNickname = useAppSelector((state) => state.telegramNick);
    const formId = telegramNickname ? 'modal_bot-change' : 'modal_bot-first';
    return (
        <>
            <aside className="bot__wrapper">
                <p className="bot__info">Бот в Telegram:</p>
                <a className="bot__a" href="https://t.me/hogmaths_bot" target="_blank">
                    <img src="/images/tg.svg"
                         alt=""/>https://t.me/hogmaths_bot</a>
                <p className="bot__info bot__person">Твой ник в Telegram:</p>
                <div className="bot__input_block"
                     onClick={() => (document.getElementById(formId) as HTMLDialogElement).showModal()}>
                    <input className="bot__input"
                           disabled
                           type="text"
                           value={telegramNickname ?? '@hogmaths_fan'}/>
                    <img src="/images/input.svg"
                         alt="" />
                </div>
                <button className="bot__button"
                    onClick={() => (document.getElementById('modal_bot-info') as HTMLDialogElement).showModal()}>
                    Для чего нужен этот бот?</button>
            </aside>
            <BotModalFirst/>
            <BotModalInfo/>
            <BotModalInfoTg/>
            <BotModalChange telegramNickname={telegramNickname}/>
        </>
        
);
}

export default Bot;